import React, { useEffect, useState } from 'react'
import './GetStarted.scss'
import Logo from '../Assets/Logo/logo.png'
import Dashboard from '../Assets/GetStarted/dashboard.png'
import Members from '../Assets/GetStarted/members.png'
import Trainners from '../Assets/GetStarted/trainners.png'
import Payments from '../Assets/GetStarted/payments.png'
import { useNavigate } from 'react-router-dom'
import { addDoc, collection, getDocs } from 'firebase/firestore'
import { auth, database } from '../FirebaseConfig/config'
import CommonAlert from '../Component/CommonAlert/CommonAlert'
import { Helmet } from 'react-helmet'
import Footer from '../Footer/Footer'

function GetStarted() {
    const [showAlert,setShowAlert] = useState(false)
    const [count,setCount] = useState({
        userCount:0,
        reviewCount:0
    })
    const navigate = useNavigate()

    useEffect(()=>{
        const getCompanyEmail = async()=>{
        const emailList = collection(database,'EmailList')
        const feedbackList = collection(database,'Feedback')
        const emailData = await getDocs(emailList)
        const feedbackData = await getDocs(feedbackList)
        const emailLists = emailData.docs.map((doc)=>({...doc.data(),id:doc.id}))
        const feedbackLists = feedbackData.docs.map((doc)=>({...doc.data(),id:doc.id}))
        setCount({...count,userCount:emailLists.length,reviewCount:feedbackLists.length})
    }
    getCompanyEmail()
    },[])

    const handleSend = async (e) =>{
        e.preventDefault()
        let clientDetails = {
            name:e.target.name.value,
            email:e.target.email.value,
            phone:e.target.phone.value,
            desc:e.target.desc.value,
            date:new Date(),
            isRead:'N'
        }
          const clientRef = collection(database,'clientDetails')

          try{
            await addDoc(clientRef,clientDetails) 
            setShowAlert(true)
            e.target.name.value = '';
            e.target.email.value = '';
            e.target.phone.value = '';
            e.target.desc.value = '';
          }catch(e){
              console.log(e,"error")
          }
    }

    useEffect(()=>{
        auth.onAuthStateChanged((existingUser)=>{
            try {
                if(existingUser?.email){
                    navigate('/dashboard')
                }
            }catch (error) {
                console.log("Error:", error);
            }
        })
    },[])
    
  return (
    <div style={{overflowX:'hidden'}}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Fitmi Studios</title>
            <meta name="description" content='Fitmi Studios offers an all-in-one gym management system to manage memberships, trainers, payments, and streamline gym operations effectively.' />
            <meta name="og:title" property="og:title" content='Fitmi Studios - Gym Management System'/>
            <meta property="og:url" content="https://www.fitmistudios.com/" />
            <meta name="og:description" property="og:title" content="Fitmi Studios is a powerful gym management platform that simplifies member registrations, tracks payments, and streamlines overall gym operations. Elevate your gym management and focus on helping your members achieve their goals."/>
            <meta name="keywords" content="fitmi studios, gym management system, fitness center management, membership tracking, trainer management, payment processing, gym dashboard" />
            <link rel="canonical" href="https://www.fitmistudios.com/" />
            <meta name="robots" content="index, follow" />
            <script type="application/ld+json">
                {`{
                "@context": "https://schema.org",
                "@type": "WebSite",
                "name": "Fitmi Studios",
                "description": "Fitmi Studios offers a comprehensive gym management system to help manage memberships, trainers, payments, and streamline fitness center operations.",
                "url": "https://www.fitmistudios.com/"
                }`}
            </script>
        </Helmet>
        <div className='header'>
            <div><img src={Logo} height='60' alt='fitmi studios logo' /></div>
            <div>
                {/* <div className='button' onClick={()=>navigate('/login')}>30 Days Free Trial</div> */}
                <div className='button' onClick={()=>navigate('/login')}>Get Started</div>
            </div>
        </div>
        <div className='spacingTop'></div>
        <div className='bodyContainer'>
            <div className='titleBox'>
                <div className='leftContainer detailTitle'>
                    <div>
                        <div className='contents'>
                            <h1 className='title titleSize ani'>Gym Management</h1>
                            <p>Welcome to <span className='title'>Fitmi Studios</span>, your comprehensive solution for seamless gym management. Our powerful web-based system is designed to help gym owners efficiently manage daily operations, focusing on enhancing your members' fitness journeys.</p>
                        </div>
                        <br/>
                        <h2 className='hint'>Easily track your pending <span className='payAni' style={{color:'red'}}>Payments</span></h2>
                        <br/>
                        {/* <button className='startBtn' onClick={()=>navigate('/login')}>30 Days Free Trial</button> */}
                        <button className='startBtn' onClick={()=>navigate('/login')}>Get Started</button>
                    </div>
                </div>
                <div>
                <div className='getCustomize detailTitle'>
                    <h2>Get Customized Support</h2>
                    <form onSubmit={handleSend}>
                        <input required name='name' placeholder='Name' /><br/>
                        <input required name='email' placeholder='Email' /><br/>
                        <input required name='phone' placeholder='Phone Number' /><br/>
                        <textarea required name='desc' placeholder='Message' /><br/><br/>
                        <div className='submitBtn'>
                            <button className='button'>Send</button>
                        </div>
                    </form>
                </div>
                </div>
            </div>
            <h1 className='brandName title'>Fitmi Studios</h1>
            <div className='listContainer'>
                <div className='cardContainer'>
                    <div>
                        <div className='card noCardColor'>
                            <div className='detailsStart detailTitle'>
                                <h2 className="detailColor">Dashboard Overview</h2>
                                <p>The Fitmi Studios dashboard provides a detailed overview of your gym's operations, displaying key metrics such as the total number of members and trainers. It highlights new members who joined this month and offers financial insights, including total revenue, monthly revenue, and a list of pending payments. This setup enables you to quickly assess the financial health and growth trends of your gym, making it easier to manage and plan for the future.</p>
                            </div>
                            <div className='imgDiv'>
                                <img className='imgWithBorder' src={Dashboard} alt='Fitmi Studios Dashboard Overview' />
                            </div>
                        </div>
                        <div className='card cardColor'>
                            <div className='imgDiv'>
                                <img className='imgHeight' src={Members} alt='Fitmi Studios Members Management' />
                            </div>
                            <div className='detailsEnd detailTitle'>
                                <h2 className="detailColor">Members Management</h2>
                                <p>The Members section of the Fitmi Studios dashboard offers robust management features. You can easily add and edit member details, manage fees, and track fitness progress. Additionally, you can filter members by criteria such as joining date, due payments, and more, making it simple to oversee your gym’s membership efficiently.</p>
                            </div>
                        </div>
                        <div className='card noCardColor'>
                            <div className='detailsStart detailTitle'>
                                <h2 className="detailColor">Trainer Management</h2>
                                <p>The Trainers section allows you to effectively manage trainer profiles. You can add new trainers, change their statuses, and export trainer information. Filtering options by date and status enhance organization and efficiency in handling trainer-related tasks.</p>
                            </div>
                            <div className='imgDiv'>
                                <img className='imgWithBorder' src={Trainners} alt='Fitmi Studios Trainers Management' />
                            </div>
                        </div>
                        <div className='card cardColor'>
                            <div className='imgDiv'>
                                <img className='imgHeight' src={Payments} alt='Fitmi Studios Payments Management' />
                            </div>
                            <div className='detailsEnd detailTitle'>
                                <h2 className="detailColor">Payments Management</h2>
                                <p>The Payments section provides a complete history of all transactions. Users can filter payment records efficiently, enabling quick access to specific payment details and ensuring accurate financial oversight.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className='feedbackMain'>
            <h2>Feedback from our clients</h2>
            <div className='feedContainer'>
                <div className='card'>
                    <h3>Peak Performance Gym</h3>
                    <p>The Fitmi Studios gym management system is so easy to use. It makes managing our members and payments a breeze!</p>
                </div>
                <div className='card'>
                    <h3>Iron Warriors Fitness</h3>
                    <p>We love the Fitmi Studios system! It has all the features we need, and it's very reliable. Highly recommended.</p>
                </div>
                <div className='card'>
                    <h3>Flex Zone Gym</h3>
                    <p>Fitmi Studios' gym management system has made our operations much smoother. The support team is also very helpful.</p>
                </div>
            </div>
        </div> */}
        <div className='activeCount'>
            <div className='activeNumber'>
                <h1>Happy customers</h1>
                <div className='countContainer'>
                    <div className='count'>
                        <div>
                            <div>Active Users</div>
                            <div className='value'>{count.userCount}+</div>
                        </div>
                    </div>
                    <div className='count'>
                        <div>
                            <div>User Reviews</div>
                            <div className='value'>{count.reviewCount}+</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        {
            showAlert?
            <CommonAlert msg="Send successfully!!" hide={setShowAlert} />:''
        }
    </div>
  )
}

export default GetStarted