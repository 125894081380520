import React from 'react'
import './PrivacyAndPolicy.scss'
import Footer from '../Footer/Footer'

function PrivacyAndPolicy() {
  return (
    <div>
        <div className='policy'>
            <h1>Privacy Policy with Data Usage Details</h1>
            <p>At <strong>Fitmi Studios</strong>, we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and protect your data when you use our website, <a href='https://www.fitmistudios.com/'>https://www.fitmistudios.com/</a>.</p>
            
            <h2>Information We Collect</h2>
            <p>We collect the following types of information:</p>
            <h5>For Registration:</h5>
            <p>Email address (required).</p>
            
            <h5>Gym Owner Data:</h5>
            <p>1. After logging in, gym owners can optionally add client details such as name, age, email address, and phone number (phone number is optional).</p>
            <p>2. Adding client details is at the discretion of the gym owner and not mandatory.</p>
            
            <h5>Payment Information:</h5>
            <p>1. Payment details for subscription plans (monthly, 6-month, and 1-year).</p>
            <p>2. Payments are processed through a secure third-party payment gateway, such as Google Pay.</p>
            
            <h2>How We Use Your Data</h2>
            <p>We use your data for the following purposes:</p>
            <p>1. To create and manage user accounts.</p>
            <p>2. To allow gym owners to manage their client details (optional).</p>
            <p>3. To process payments for subscriptions securely.</p>
            <p>4. To improve website functionality and provide a better user experience.</p>
            
            <h2>Payment Data Usage</h2>
            <p>1. Payments are processed securely through third-party payment gateways such as Google Pay.</p>
            <p>2. We do not store sensitive payment information like credit card or bank account numbers on our servers.</p>
            <p>3. To process payments for subscriptions securely.</p>
            <p>4. Personal and payment details provided during checkout are shared with the payment gateway to process transactions in compliance with their security and privacy standards.</p>
            
            <h2>Data Security</h2>
            <p>We implement secure methods to protect your information, including:</p>
            <p>1. Encryption during data transmission (e.g., SSL).</p>
            <p>2. Regular monitoring of our systems to prevent unauthorized access.</p>
            <p>However, no method of transmission or storage is completely secure, so users should take care to safeguard their login credentials.</p>
            
            <h2>Your Choices</h2>
            <p>1. Gym owners can decide whether to add client details.</p>
            <p>2. Adding phone numbers is optional and not required for the service.</p>
            <p>3. You may request access to, update, or delete your personal information by contacting us.</p>
            
            <h2>Return and Refund Policy</h2>
            <p>1. <strong>Subscription payments are non-refundable.</strong></p>
            <p>2. Once activated, subscriptions (monthly, 6-month, or 1-year) remain valid until the end of their term.</p>
            <p>3. Cancellations will not result in refunds for the remaining period, but access to services will continue until the billing cycle ends.</p>

            <h3>Contact Us</h3>
            <p>If you have questions about this Privacy Policy or our practices, please contact us:</p>
            <h2>Fitmi Studios</h2>
            <p>Website: <a href='https://www.fitmistudios.com/'>https://www.fitmistudios.com/</a></p>
            <p>Email: akajith919@gmail.com</p>
        </div>
        <Footer/>
    </div>
  )
}

export default PrivacyAndPolicy