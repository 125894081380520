import React from 'react'
import Footer from '../Footer/Footer'

function RefundPolicy() {
  return (
    <div>
        <div className='policy'>
            <h1>Return and Refund Policy</h1>
            <p>At <strong>Fitmi Studios</strong>, we strive to provide transparent policies regarding payments and subscriptions. By using our service, you acknowledge and agree to the following:</p>

            <h2>Subscription Plans:</h2>
            <p>We offer the following subscription options:</p>
            <p>1. <strong>Monthly</strong></p>
            <p>2. <strong>6-month</strong></p>
            <p>3. <strong>1-year</strong></p>

            <h2>Refund Policy:</h2>
            <p>1. All payments made for subscriptions are non-refundable, including for partially used subscription periods.</p>
            <p>2. Once a subscription is activated, it will remain valid for the purchased duration (monthly, 6 months, or 1 year).</p>

            <h2>Cancellation Policy:</h2>
            <p>1. You may cancel your subscription at any time, but no refunds will be provided for the remaining period of your plan.</p>
            <p>2. After cancellation, your access will continue until the end of the current billing cycle.</p>
            <br/>
            <p>If you have any questions regarding this Return and Refund Policy, please contact us at:</p>
            <h2>Fitmi Studios</h2>
            <p>Website: <a href='https://www.fitmistudios.com/'>https://www.fitmistudios.com/</a></p>
            <p>Email: akajith919@gmail.com</p>
        </div>
        <Footer/>
    </div>
  )
}

export default RefundPolicy