import React from 'react'
import './Admin.scss'
import Logo from '../Assets/Logo/logo.png'
import { useNavigate } from 'react-router-dom'

function AdminLogin({isLoggedIn}) {
    const navigate = useNavigate()

    const handleGo =(e)=>{
        e.preventDefault()
        const email = e.target.email.value
        const pass = e.target.pass.value
        
        if(email==='akajith919@gmail.com' && pass==='Ajithkum@6'){
            isLoggedIn(true)
            navigate('/adminboard')
        }
    }
  return (
    <div className='adminLogin'>
        <form onSubmit={handleGo}>
            <img src={Logo} /><br/>
            <input required name='email' placeholder='Email' /><br/>
            <input required name='pass' type='password' placeholder='Password' /><br/>
            <button>Login</button>
        </form>
    </div>
  )
}

export default AdminLogin