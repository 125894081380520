import React from 'react'
import close from '../../Assets/close.png'
import { addDoc, collection } from 'firebase/firestore'
import { database } from '../../FirebaseConfig/config'

function Support(props) {

  const handleSend = (e) =>{
    props.hide(false)
    let feedback = {
      cName: props.cDetails.cName,
      email: props.cDetails.email,
      feed:e.target.feed.value,
      cId:props.cDetails.id,
      date:new Date(),
      isRead:'N'
    }
    const supportRef = collection(database,'Feedback')
    addDoc(supportRef,feedback)
  }
  return (
    <div>
      <div className='registrationPopup'>
        <div className='registrationForm'>
          <button onClick={()=>props.hide(false)} className='cloaseButton'><img src={close} height="15px"/></button>
          <form onSubmit={(e)=>handleSend(e)}>
            <div>
              <div className='formTitle'>Send Feedback</div><br/>
            </div>
            <textarea required name='feed' className='feedback' placeholder='Describe your feedback' />
            <div className='titleContainer'><span className='formTitle'></span></div><br/>
            <button style={{cursor:'pointer'}}>Send</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Support