import React, { useEffect, useState } from 'react'
import './CustomizeAndFeedback.scss'
import SideMenu from '../../Component/SideMenu/SideMenu'
import { collection, doc, getDocs, orderBy, query, updateDoc } from 'firebase/firestore';
import { database } from '../../FirebaseConfig/config';

function Customize({from}) {
    const [customize,setCoustomize] = useState([])
    const [reRender,setReRender] = useState(false)

    const handleCheck =async(val)=>{
      const read = val.isRead == 'N'? 'Y':'N'
      try{
    const docRef =  doc(database,from=='customize'?'clientDetails':'Feedback', val.id);
        await updateDoc(docRef, {
          isRead:read,
        });
        setReRender(!reRender)
      }catch(e){
          console.log("error",e)
      }    
    }

    useEffect(()=>{
        const getCompanyName = async()=>{
            const valueDb = collection(database,from=='customize'?'clientDetails':'Feedback')
            const orderByData = query(valueDb, orderBy('date', 'asc'));
            const data = await getDocs(orderByData)
            const clientDetail = data.docs.map((doc)=>({...doc.data(),id:doc.id}))
            setCoustomize(clientDetail)
        }
        getCompanyName()
      },[reRender,from])

  return (
    <div>
        <div className='pageSplit'>
            <div>
            <SideMenu from="admin"/>
            </div>
            <div className='mainContainer' style={{overflow:'auto'}}>
                <div className='custDetail'>
                  {
                      customize.map((val)=>(
                      <div onClick={()=>handleCheck(val)} className={val.isRead=='N'?'singleCust noRead':'singleCust read'} key={val.id}>
                         {
                          from == 'customize'?
                          <div>
                              <div className='detailContainer'>
                                <div className='label'>Name:</div> <span>{val.name}</span>
                              </div>
                              <div className='detailContainer'>
                                <div className='label'>Email:</div> <span>{val.email}</span>
                              </div>
                              <div className='detailContainer'>
                                <div className='label'>Phone:</div> <span>{val.phone}</span>
                              </div>
                              <div className='detailContainer'>
                                <div className='label'>Requirment:</div> <span>{val.desc}</span>
                              </div>
                          </div>:
                          <div>
                              <div className='detailContainer'>
                                <div className='label'>Gym:</div> <span>{val.cName}</span>
                              </div>  
                              <div className='detailContainer'>
                                <div className='label'>Email:</div> <span>{val.email}</span>
                              </div>  
                              <div className='detailContainer'>
                                <div className='label'>Feedback:</div> <span>{val.feed}</span>
                              </div>  
                          </div>
                         }
                      </div>
                      )
                      )
                  }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Customize