import React from 'react'
import './Footer.scss'
import { useNavigate } from 'react-router-dom';
function Footer() {
    const navigate = useNavigate()
    const getYear = new Date().getFullYear();

  return (
    <div>
        <div className='footer'>
            <h1>Fitmi Studios</h1>
            <p>Empowering fitness journeys, one rep at a time. Gym management pro | Member tracking & training | Seamless payment solutions</p> 
            <div className='footerPolicy'>
                <span onClick={()=>navigate('/privacy-policy')}>Privacy Policy</span> | <span onClick={()=>navigate('/refund-policy')}>Refund Policy</span><br/>
            </div>
            <span className='copyWrite'>&copy;{getYear}, Coding Comics. All Rights Reserved.</span>
        </div>
    </div>
  )
}

export default Footer