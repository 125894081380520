import React, { useEffect, useState } from 'react'
import './Login.scss'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { auth, provider } from '../FirebaseConfig/config'
import { useNavigate } from 'react-router-dom'
import Slider1 from '../Assets/Slider/slider1.png'
import Slider2 from '../Assets/Slider/slider2.png'
import Slider3 from '../Assets/Slider/slider3.png'
import Slider4 from '../Assets/Slider/slider4.png'
import Google from '../Assets/google.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Logo from '../Assets/Logo/logo.png'
import CommonAlert from '../Component/CommonAlert/CommonAlert'
import { Helmet } from 'react-helmet'
import ResetPassword from '../Component/CommonAlert/ResetPassword'

const SliderData = [
    {
        image:Slider3,
        alt:"Say goodbye to outdated pen-and-paper methods for managing your gym",
        desc:"Say goodbye to outdated pen-and-paper methods for managing your gym. Transition to our innovative FitMi platform for efficient gym operations.",
    },
    {
        image:Slider1,
        alt:"Monitor your gym's performance effortlessly with our user-friendly dashboard",
        desc:"Monitor your gym's performance effortlessly with our user-friendly dashboard, designed to enhance gym management and boost efficiency.",
    },
    {
        image:Slider2,
        alt:"Simplify your billing processes with our platform",
        desc:"Simplify your billing processes with our platform, ensuring you maintain accurate and secure billing details for your gym for a lifetime.",
    },
    {
        image:Slider4,
        alt:"Discover the future of gym management today with Fitmi Studios",
        desc:"Discover the future of gym management today with Fitmi Studios - your pathway to achieving success in your fitness business starts here!",
    },
]

function Login() {
    const [show,setShow] = useState(false)
    const [login, setLogin] = useState(false);
    const [reset, setReset] = useState(false);
    const [showPass,setShowPass] = useState(false)
    const [error,setError] = useState({
        isError:false,
        msg:''
    })

    const navigate = useNavigate() //navigate to specific location

    // google signUp
    const handleClick =()=>{
        if (navigator.onLine) {
            try{
                signInWithPopup(auth,provider).then((ssoData)=>{
                    navigate('/dashboard')
                })
            }catch(error){
                console.log("error",error)
            }
        } else {
            setShow(true)
            console.log('No internet connection.');
        }
    }

    const handleSubmit = (e, type) => {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;
        if (type == "signup") {
          createUserWithEmailAndPassword(auth, email, password)
            .then((data) => {
              navigate("/dashboard");
            })
            .catch((err) => {
                setShow(true)
                setError({...error,isError:true,msg:err.code})
            });
        } else {
          signInWithEmailAndPassword(auth, email, password)
            .then((data) => {
              navigate("/dashboard");
            })
            .catch((err) => {
                setShow(true)
                setError({...error,isError:true,msg:err.code})
            });
        }
      };

    // get existing user data
    useEffect(()=>{
        auth.onAuthStateChanged((existingUser)=>{
            try {
                if(existingUser?.email){
                    navigate('/dashboard')
                }
            }catch (error) {
                console.log("Error:", error);
            }
        })

         // Preload the LCP image (here we preload the first image dynamically)
         const preloadImage = document.createElement('link');
         preloadImage.rel = 'preload';
         preloadImage.as = 'image';
         preloadImage.href = SliderData[0].image; // Preloading the first image (LCP image)
         document.head.appendChild(preloadImage);
 
         // Clean up to remove the link if the component is unmounted
         return () => {
             document.head.removeChild(preloadImage);
         };

    },[])
    
  return (
    <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Fitmi Studios</title>
            <meta name="description" content='Fitmi Studios offers a powerful gym management system designed to streamline operations. Manage memberships, trainers, payments, and more with an intuitive dashboard built for fitness centers.' />
            <meta name="og:title" property="og:title" content='Fitmi Studios | Advanced Gym Management System'/>
            <meta property="og:url" content="https://www.fitmistudios.com/login" />
            <meta name="og:description" property="og:title" content="Fitmi Studios provides an all-in-one gym management solution for fitness centers. Streamline member registration, track payments, and manage trainers with our easy-to-use platform."/>
            <meta name="keywords" content="fitmi studios, gym management system, manage memberships, trainer management, payment tracking, fitness center management, gym software, gym dashboard" />
            <link rel="canonical" href="https://www.fitmistudios.com/login" />
            <meta name="robots" content="index, follow" />
            <script type="application/ld+json">
                {`{
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Fitmi Studios",
                "description": "Fitmi Studios is a comprehensive gym management system designed to help fitness centers manage memberships, trainers, payments, and more with a user-friendly dashboard.",
                "url": "https://www.fitmistudios.com/login"
                }`}
            </script>
        </Helmet>
        <div>
            <div className='loginScrinSplit'>
                <div className='leftScreen'>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false} emulateTouch={true} showArrows={false}>
                        {
                            SliderData.map((val,i)=> 
                            <div key={i}>
                                <div>
                                    <img src={val.image} alt={val.alt}  style={{ maxWidth: '50%', height: '350px' }}/>
                                    <div className='cotes'>
                                        <p className='cotesDesc'>{val.desc}</p>
                                    </div>
                                </div>
                            </div>
                            )
                        }
                    </Carousel>
                </div>
                <div className='rightScreen'>
                    <div className='loginContainer'>
                        <div>
                            <div className='logoContainer'>
                                <img height='70px' src={Logo} alt='fitmi studios logo' />
                            </div>
                            <h1>Experience Seamless Fitness Management <br /> Solutions for Your Gym</h1>
                            <div>
                                <div>
                                    <span className='loginTitle'>{login ? "Sign In" : "Sign Up"}</span>
                                    <div className='loginLabel'>
                                        {
                                            login?
                                            <div>
                                                <span className='loginType'>Don't have an account?</span>
                                                <span className='signInAndUp' onClick={() => setLogin(false)}>Sign up</span>
                                            </div>:
                                            <div>
                                                <span className='loginType'>Already have an account?</span>
                                                <span className='signInAndUp' onClick={() => setLogin(true)}>Sign in</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <form className='loginForm' onSubmit={(e) => handleSubmit(e, login ? "signin" : "signup")}>
                                    <input required name="email" placeholder='Email' /><br/>
                                    <div className='showAndHideContainer'>
                                        <span onClick={()=>setShowPass(!showPass)} className='showHidePassword'>{showPass?"Hide": "Show"}</span>
                                    </div>
                                    <input required name="password" type={showPass?'text':'password'} placeholder='Password' /><br/>
                                    <p onClick={()=>setReset(true)}>Forgot Password ?</p><br/>
                                    <button className='btnAni'>{login ? "SignIn" : "SignUp"}</button>
                                </form>
                            </div>
                            <span className='splitterOr'>Or</span>
                            <div className='googleLogin'>
                                <div className='googleBtn' onClick={handleClick}><img src={Google} alt='google signup and signin' height='20px'/><span>Continue with Google</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            show ?
            <CommonAlert msg={error.isError?(error.msg=='auth/email-already-in-use'?'Email already exists, please sign in.':error.msg=='auth/user-not-found'?'The entered email was not found. Please sign up.':error.msg=='auth/weak-password'?'Password is weak.':error.msg=='auth/wrong-password'?'Password is mismatching.':'Please check your email and password.'):'No Internet'} hide={setShow} />:''
        }
        {
            reset ?
            <ResetPassword  hide={setReset} />:''
        }
    </div>
  )
}

export default Login