import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../../FirebaseConfig/config";
import CommonAlert from "./CommonAlert";

function ResetPassword(props){
    const [showReset,setShowReset] = useState(false)
    const [msg,setMsg] = useState('')

    const handleSubmit = async(e)=>{
            e.preventDefault()
            const emalVal = e.target.email.value;
            sendPasswordResetEmail(auth,emalVal).then(data=>{
                setMsg("Please check your gmail.")
                setShowReset(true)
            }).catch(err=>{
                setShowReset(true)
                setMsg(err.code)
            })
    }

    return(
        <div>
            <div className='commonBg'>
                <div className='commpnAlert'>
                    <div>
                        {
                            showReset?
                            <div>
                                <span>{msg == "auth/user-not-found"?"Please enter a valid email address.":"Please check your Email ."}</span><br/><br/>
                                <div  className='commonContentBtn'>
                                    <button onClick={()=>props.hide(false)}>Close</button>
                                </div>
                            </div>:
                            <form onSubmit={handleSubmit}>
                                <div className='commonContent'>
                                    <div>
                                        <span className="resetTitle">Reset Password</span><br/><br/>
                                        <input required name="email" placeholder='Email' /><br/>
                                    </div>
                                </div>
                                <div  className='commonContentBtn'>
                                    <button>Reset</button>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ResetPassword